import {T} from '@eksab/i18n';
import Joi from 'joi';
import {RegisterParams} from './components/Register';

export const validateRegisterForm = (t: T, params: RegisterParams, numberRegex: string, phonePrefix: string) => {
  const schema = Joi.object({
    name: Joi.string()
      .required()
      .messages({base: t('auth.name-error')}),
    email: Joi.string()
      .empty('')
      .email({tlds: {allow: false}})
      .messages({base: t('auth.email-error')}),
    phone_number: Joi.string().empty('').pattern(new RegExp(numberRegex)).message(t('auth.phone-error')),
    password: Joi.string()
      .required()
      .messages({base: t('auth.password-error')}),
    recaptcha: Joi.string().required(),
  });

  const {error} = schema.validate({...params, phone_number: `${phonePrefix}${params.phone_number}`});

  return error;
};

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
