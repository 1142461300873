import React, {useState} from 'react';
import Script from 'next/script';
import {useRouter} from 'next/router';
import NextLink from 'next/link';
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Text,
  useToast,
  Link as ChakraLink,
  Flex,
  Center,
} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {Bold, Br} from '@eksab/i18n/components';
import {EmailIcon, InfoCardIcon, LockIcon, ViewIcon} from '@eksab/assets/icons';
import {config} from '@eksab/config';

import {AccountCreated, VerifyNumber} from './';
import {getFlagEmoji, validateRegisterForm} from '../utils';
import {forceEnglishDigits, getCountry, getLanguage} from '@eksab/util';
import {useAppSettingsQuery} from '@eksab/hooks';
import {regionsPhonePrefix} from '@eksab/const';

type RegisterProps = {
  isModal?: boolean;
  closeModal?: () => void;
  onLoginClick?: () => void;
};

export type RegisterParams = {
  name: string;
  email?: string;
  phone_number: string;
  password: string;
  recaptcha: string;
  referrer?: string;
};

export function Register({onLoginClick, isModal, closeModal}: RegisterProps) {
  const router = useRouter();
  const [values, setValues] = useState<RegisterParams>({
    name: '',
    email: '',
    password: '',
    phone_number: '',
    recaptcha: '',
  });
  const [accountCreated, setAccountCreated] = useState(false);
  const [verifyNumber, setVerifyNumber] = useState(false);
  const toast = useToast();

  const t = useTranslate();

  const {data: settings} = useAppSettingsQuery();

  const onVerificationSuccess = () => {
    if (isModal) {
      return closeModal?.();
    }

    setAccountCreated(true);
  };

  const goBack = () => setVerifyNumber(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mixpanel?.track('Register.Confirm');

    window.grecaptcha?.ready(() =>
      window.grecaptcha!.execute(config.recaptchaSiteKey!, {action: 'submit'}).then((token) => {
        const valuesWithRecaptcha = {
          ...values,
          recaptcha: token,
        };
        setValues(valuesWithRecaptcha);

        const error = validateRegisterForm(
          t,
          valuesWithRecaptcha,
          settings?.phone_number_regex || '',
          regionsPhonePrefix[getCountry(router.locale)],
        );
        if (error) return toast({title: error.message, status: 'error'});

        setVerifyNumber(true);
      }),
    );
  };

  return accountCreated ? (
    <AccountCreated />
  ) : verifyNumber ? (
    <VerifyNumber values={values} onVerificationSuccess={onVerificationSuccess} goBack={goBack} />
  ) : (
    <>
      <Script src={`https://www.google.com/recaptcha/api.js?render=${config.recaptchaSiteKey}`} strategy="lazyOnload" />

      <Spacer />
      <Text fontWeight="black" fontSize="2xl" mb="1" data-testid="join_to_win">
        {t('auth.join_to_win')}
      </Text>

      <Text fontSize="sm" mb="10" opacity="0.6">
        {t('auth.brag', {b: Bold, br: Br})}
      </Text>

      <RegisterForm values={values} setValues={setValues} onSubmit={onSubmit} />
      <Spacer />

      <NextLink
        href={!isModal ? {pathname: '/login', query: router.query} : {href: null, query: router.query}}
        scroll={!isModal}
        passHref
        legacyBehavior
      >
        <ChakraLink
          as="button"
          onClick={onLoginClick}
          fontSize="xs"
          fontWeight="700"
          color="gray.500"
          _hover={{color: 'black'}}
        >
          {t('auth.have_an_account')}{' '}
          <Text as="span" textDecor="underline" color="unset">
            {t('app.actions.login')}
          </Text>
        </ChakraLink>
      </NextLink>
    </>
  );
}

type FormParams = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  values: RegisterParams;
  setValues: React.Dispatch<React.SetStateAction<RegisterParams>>;
};

function RegisterForm({onSubmit, values, setValues}: FormParams) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const locale = useRouter().locale;

  const t = useTranslate();

  const togglePasswordVisibility = () => setPasswordVisible((current) => !current);

  const onChange = (key: keyof RegisterParams) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (key === 'phone_number') {
      value = forceEnglishDigits(value);
    }

    setValues((old) => ({...old, [key]: value}));
  };

  return (
    <form onSubmit={onSubmit}>
      <FormControl isRequired mb="2.5">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <InfoCardIcon width="5" height="4" />
          </InputLeftElement>

          <Input
            id="name"
            placeholder={t('auth.name')}
            value={values.name}
            onChange={onChange('name')}
            autoComplete="name"
            isRequired
          />
        </InputGroup>
      </FormControl>

      <Flex gap="2.5" flexDirection={getLanguage(locale) === 'ar' ? 'row-reverse' : 'row'}>
        <Center borderWidth={1} w="10" h="10" rounded="sm">
          {getFlagEmoji(getCountry(locale))}
        </Center>
        <FormControl isRequired mb="2.5">
          <InputGroup dir="ltr">
            {getLanguage(locale) === 'en' ? (
              <InputLeftElement pointerEvents="none" fontWeight="600" w={14} alignItems="center">
                {regionsPhonePrefix[getCountry(locale)]}
              </InputLeftElement>
            ) : (
              <InputRightElement pointerEvents="none" fontWeight="600" w={14} alignItems="center">
                {regionsPhonePrefix[getCountry(locale)]}
              </InputRightElement>
            )}
            <Input
              paddingStart={14}
              paddingEnd={0}
              id="phone"
              type="tel"
              inputMode="tel"
              autoComplete="tel"
              placeholder={t('auth.number')}
              value={values.phone_number}
              onChange={onChange('phone_number')}
            />
          </InputGroup>
        </FormControl>
      </Flex>

      <FormControl mb="2.5">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <EmailIcon width="4" height="4" />
          </InputLeftElement>

          <Input
            id="email"
            type="email"
            autoComplete="email"
            placeholder={`${t('auth.email')} ${t('app.field.optional')}`}
            value={values.email}
            onChange={onChange('email')}
            ps="16"
          />
        </InputGroup>
      </FormControl>

      <FormControl isRequired mb="5">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <LockIcon />
          </InputLeftElement>

          <Input
            id="password"
            type={passwordVisible ? 'text' : 'password'}
            autoComplete="new-password"
            placeholder={t('auth.password')}
            value={values.password}
            onChange={onChange('password')}
          />

          <InputRightElement>
            <ViewIcon opacity={passwordVisible ? 1 : 0.4} onClick={togglePasswordVisibility} />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Text fontSize="10" color="gray.500" textAlign="start" mb="2.5">
        {t('auth.recaptcha', {
          a1: (privacy) => (
            <ChakraLink
              textDecor="underline"
              _hover={{color: 'black'}}
              color="unset"
              href="https://policies.google.com/privacy?hl=en-GB"
              fontSize="10"
            >
              {privacy}
            </ChakraLink>
          ),
          a2: (terms) => (
            <ChakraLink
              textDecor="underline"
              _hover={{color: 'black'}}
              color="unset"
              href="https://policies.google.com/terms?hl=en-GB"
              fontSize="10"
            >
              {terms}
            </ChakraLink>
          ),
        })}
      </Text>

      <Button w="100%" mb="7" type="submit">
        {t('app.actions.register')}
      </Button>
    </form>
  );
}
