import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {Button, Spacer, Text, useToast, Flex} from '@chakra-ui/react';
import {ChevronLeftIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import {regionsPhonePrefix} from '@eksab/const';
import {getCountry} from '@eksab/util';

import {OTPInput} from './';
import {RegisterParams} from './Register';
import {useRegisterMutation, useOTPMutation} from '../hooks';
import {OTP} from '../types';

interface VerifyNumberProps {
  values: RegisterParams;
  onVerificationSuccess: () => void;
  goBack: () => void;
}

export function VerifyNumber({values, onVerificationSuccess, goBack}: VerifyNumberProps) {
  const toast = useToast();
  const {push, locale} = useRouter();
  const [seconds, setSeconds] = useState(30);
  const [throttled, setThrottled] = useState(false);
  const [otp, setOtp] = useState<OTP>(['', '', '', '', '', '']);
  const [intervalId, setIntervalId] = useState<number | undefined>();
  const [sendBtnClicked, setSendBtnClicked] = useState<boolean>(false);

  const t = useTranslate();
  const otpMutation = useOTPMutation();
  const registerMutation = useRegisterMutation({
    onSuccess: () => {
      toast({title: t('auth.phone_number_verified'), status: 'success'});
      onVerificationSuccess();
    },
    onError: (e) => {
      if (e.response?.status === 429) return setThrottled(true);

      toast({
        status: 'error',
        title: e.response?.data.errors ? (
          <>
            {Object.values<string>(e.response.data.errors)
              .flat()
              .map((message) => (
                <p key={message}>{message}</p>
              ))}
          </>
        ) : (
          t('auth.otp-error')
        ),
      });

      push('/register');
    },
  });

  const isInvalidOTP = otp.some((char) => char.length !== 1);

  const sendOTP = () => {
    otpMutation.mutate(
      {phone_number: `${regionsPhonePrefix[getCountry(locale)]}${values.phone_number}`},
      {
        onSuccess: () => {
          toast({title: t('auth.otp_sent'), status: 'success'});
        },
        onError: (e) => {
          if (e.response?.status === 429) {
            setThrottled(true);
          } else {
            if (e.response?.data.errors?.phone_number) {
              toast({title: e.response?.data.errors.phone_number[0], status: 'error'});
            } else {
              toast({title: e.response?.data?.message ?? t('app.errors.try-again'), status: 'error'});
            }
            goBack();
          }
        },
      },
    );
  };

  const onVerifyClicked = () => {
    if (!values.email) {
      delete values.email;
    }

    registerMutation.mutate({
      ...values,
      phone_number: `${regionsPhonePrefix[getCountry(locale)]}${values.phone_number}`,
      referrer: sessionStorage.getItem('referrer') ?? undefined,
      otp: otp.join(''),
    });
  };

  useEffect(() => {
    if (throttled) {
      setSeconds(60 * 5);
      const temp = window.setInterval(() => {
        setSeconds((old) => {
          if (old === 1) {
            clearInterval(temp);
            setThrottled(false);
          }
          return old - 1;
        });
      }, 1000);

      clearInterval(intervalId);
      setIntervalId(temp);

      toast({title: t('auth.otp-throttle'), status: 'error'});
    }

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttled]);

  useEffect(() => {
    sendOTP();
    const temp = window.setInterval(() => {
      setSeconds((old) => {
        if (old === 1) {
          clearInterval(temp);
        }
        return old - 1;
      });
    }, 1000);
    setIntervalId(temp);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendBtnClicked]);

  return (
    <>
      <Spacer />
      <Flex alignItems="center" mb="1">
        <ChevronLeftIcon cursor="pointer" onClick={goBack} />
        <Text fontWeight="800" m="auto" fontSize="lg">
          {t('auth.verify_number')}
        </Text>
      </Flex>
      <Text fontSize="sm" mb="10">
        {t('auth.enter_verification_code')}
      </Text>
      <OTPInput otp={otp} setOtp={setOtp} mb="10" mx="auto" />
      <Button mb="7" onClick={onVerifyClicked} isDisabled={throttled || isInvalidOTP}>
        {t('auth.verify')}
      </Button>
      <Spacer />
      {seconds ? (
        <Text opacity="0.4" fontSize="xs" fontWeight="bold">
          {t('auth.resend_in', {seconds: seconds.toLocaleString(undefined, {minimumIntegerDigits: 2})})}
        </Text>
      ) : (
        <Text
          display="inline"
          as="button"
          opacity="0.6"
          _hover={{opacity: 1, textDecor: 'underline'}}
          fontSize="xs"
          fontWeight="bold"
          onClick={() => {
            setSeconds(30);
            setSendBtnClicked((prev) => !prev);
          }}
        >
          {t('auth.resend')}
        </Text>
      )}
    </>
  );
}
